import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {IUserResponse} from "../interfaces/user.interface";

@Injectable({
  providedIn: 'root',
})
export class TokenManagementService {
  public user: IUserResponse|null = null;
  constructor(private router: Router) {}

  setToken(token: string) {
    localStorage.setItem('token', token);
  }

  setUser(user: IUserResponse) {
    this.user = user;
  }

  getUser() {
    return this.user;
  }

  getToken(): string {
    const token = localStorage.getItem('token');
    return token ?? '';
  }

  hasToken(): boolean {
    return !!this.getToken();
  }

  removeToken() {
    localStorage.removeItem('token');
  }

  removeAllSession() {
    this.removeToken();
    localStorage.clear();
    this.router.navigate(['/']).then(() => {
      window.location.reload();
    });
  }
}
