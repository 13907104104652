import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, Subject, throwError, EMPTY } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { IRefreshTokenRequest } from '../interfaces/auth.interface';
import { AuthService } from '../services/auth.service';
import { ToastService } from '../services/toast.service';

@Injectable()
export class RequestErrorInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: Subject<any> = new Subject<any>();
  private tooManyRequestsInProgress = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private toastService: ToastService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            if (!this.refreshTokenInProgress) {
              this.refreshTokenInProgress = true;
              this.refreshTokenSubject.next(null);

              const rToken = localStorage.getItem('refreshToken');
              const rTokenManage: IRefreshTokenRequest = {
                token: String(rToken),
              };

              return this.authService.refreshUserToken(rTokenManage).pipe(
                switchMap((refreshResponse) => {
                  this.refreshTokenInProgress = false;
                  this.refreshTokenSubject.next(refreshResponse);
                  localStorage.setItem(
                    'token',
                    String(refreshResponse.response?.token)
                  );

                  const modifiedRequest = request.clone({
                    setHeaders: {
                      Authorization: `Bearer ${refreshResponse.response?.token}`,
                    },
                  });

                  return next.handle(modifiedRequest);
                }),
                catchError((refreshError) => {
                  this.refreshTokenInProgress = false;
                  this.authService.logout();
                  return throwError(refreshError);
                })
              );
            } else {
              return this.refreshTokenSubject.pipe(
                switchMap(() => {
                  return next.handle(request);
                }),
                take(1)
              );
            }
          } else {
            this.toastService.error('Error: ' + error.error.message);
            return throwError(error);
          }
        } else {
          this.toastService.error('Error: ' + error.error.message);
          return throwError(error);
        }
      }),

      catchError((err: HttpErrorResponse) => {
        if (err.status === 429) {
          // Handle 429 status code if needed
        } else if (err instanceof HttpErrorResponse && err.status !== 401) {
          // Handle other error cases
          let errors = err.error?.errors;
          if (errors && Object.keys(errors)?.length > 0) {
            if (errors?.[Symbol.iterator]) {
              for (let er of errors) {
                for (let i of errors[er]) {
                  this.toastService.error(i);
                }
              }
            } else {
              for (let er in errors) {
                for (let i of errors[er]) {
                  this.toastService.error(i);
                }
              }
            }
          } else {
            this.toastService.error(err.error.message);
          }
        }
        // Propagate the error after handling
        return throwError(err);
      })
    );
  }
}
