import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {LoadingComponent} from "./shared/loading/loading.component";
import {FormsModule} from "@angular/forms";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {TokenInterceptor} from "./core/interceptors/token.interceptor";
import {NgbModule, NgbToastModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import { RequestErrorInterceptor } from './core/interceptors/request-error.interceptor';
import { GoogleMapsModule } from '@angular/google-maps';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgbModule,
    NgbToastModule,
    NgbTooltipModule,
    GoogleMapsModule,
    LoadingComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestErrorInterceptor,
      multi: true,
    },
  ],
  exports: [
    LoadingComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
