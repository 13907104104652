import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import { ILoginRequest, ILoginResponse } from '../interfaces/auth.interface';
import { GeneralResponse } from '../interfaces/generic-response.interface';
import { ResourceService } from './resource.service';
import { TokenManagementService } from './token-managment.service';
import {IUserResponse} from "../interfaces/user.interface";

@Injectable({
  providedIn: 'root',
})
export class UserService extends ResourceService<
  IUserResponse
> {

  getUsers(data: {} = {}): Observable<GeneralResponse<IUserResponse[]>> {
    return this.httpClient.get(this.baseEndpoint + '/users', {
      params: data
    });
  }

  me(): Observable<GeneralResponse<IUserResponse>> {
    return this.get('/me');
  }

  createUser(newUser: IUserResponse): Observable<GeneralResponse<IUserResponse>> {
    return this.post('/users', newUser);
  }

  updateUser(id: number, user: IUserResponse): Observable<GeneralResponse<IUserResponse>> {
    return this.patch('/users' , id, user);
  }

  updatePharmacies(selectedUser: IUserResponse, pharmacies: string[]): Observable<GeneralResponse<IUserResponse>> {
    return this.httpClient.put(this.baseEndpoint + '/users/' + selectedUser.id + '/pharmacies',{
      pharmacies : pharmacies
    });
  }

  import(file_id: number | null) {
    return this.post('/users/' + file_id + '/import',{});
  }
}
