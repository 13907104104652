import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenManagementService } from '../services/token-managment.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanLoad {
  constructor(
    private router: Router,
    private tokenService: TokenManagementService
  ) {}

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    const hasToken = this.tokenService.hasToken();
    return hasToken ? true : this.router.navigate(['/auth/login'], {});
  }
}
