import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject, tap} from 'rxjs';
import {IAdminDataResponse, ILoginRequest, ILoginResponse, IRefreshTokenRequest} from '../interfaces/auth.interface';
import {GeneralResponse} from '../interfaces/generic-response.interface';
import {ResourceService} from './resource.service';
import {TokenManagementService} from './token-managment.service';
import {IUserResponse} from "../interfaces/user.interface";
import {Router} from "@angular/router";
import {ToastService} from "./toast.service";

@Injectable({
  providedIn: 'root',
})
export class AuthService extends ResourceService<
  ILoginResponse | any
> {
  private readonly endPoint = '/auth/login';
  public token: string | null = null;
  public tokenExpiresAt: Date | null = null;
  public refreshToken: string | null = null;
  public user: IUserResponse | null = null;
  public dashboardData : any;

  hasToken() {
    return !!this.token;
  }  

  login(data: ILoginRequest): Observable<GeneralResponse<ILoginResponse>> {
    this.removeCompany();
    return this.post(this.endPoint, data);
  }

  successLogin(login: ILoginResponse, company: string) {
    this.token = login.token;
    localStorage.setItem('token', this.token);

    this.refreshToken = login.refresh_token;
    localStorage.setItem('refreshToken', this.refreshToken);

    this.tokenExpiresAt = login.token_expires_at;
    localStorage.setItem('tokenExpiresAt', login.token_expires_at.toString());
    localStorage.setItem('company', company);

    this.setUser(login.user);
  }

  setUser(user: IUserResponse) {
    this.user = user;
  }

  logout() {
    this.token = null;
    this.user = null;
    localStorage.removeItem('token');
  }

  refreshUserToken(
    token: IRefreshTokenRequest
  ): Observable<GeneralResponse<ILoginResponse>> {
    return this.post('/auth/refresh-token',
      token
    );
  }

}
