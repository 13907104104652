import { Component, OnInit, TemplateRef } from '@angular/core';
import { AuthService } from './core/services/auth.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserService } from './core/services/user.service';
import { HttpClient } from '@angular/common/http';
import { ToastService } from './core/services/toast.service';
import { TokenManagementService } from './core/services/token-managment.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'FFT Menu Builder';
  isLoading: boolean = false;

  constructor(
    public router: Router,
    public authService: AuthService,
    public userService: UserService,
    public toastService: ToastService,
  ) {}

  isTemplate(toast: any) {
    return toast.textOrTpl instanceof TemplateRef;
  }

  ngOnInit() {
   
  }

}
