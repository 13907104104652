import {Injectable, TemplateRef} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor() { }

  toasts: any[] = [];

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  error(text: string) {
    this.clear();
    this.show(text, {classname: 'bg-danger text-white ', delay: 9000});
  }

  success(text: string) {
    this.clear();
    this.show(text, {classname: 'bg-success text-white ', delay: 7000});
  }

  warning(text: string) {
    this.clear();
    this.show(text, {classname: 'bg-warning-subtle', delay:7000});
  }
  
  remove(toast: any) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }

  clear() {
    this.toasts.splice(0, this.toasts.length);
  }

}
