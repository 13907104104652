import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { GeneralResponse } from '../interfaces/generic-response.interface';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root',
})
export abstract class ResourceService<G> {
  private readonly environment = environment;
  public baseEndpoint: string;
  public companySlug: string = '';
  public locale: string = 'en';

  constructor(protected httpClient: HttpClient) {
    this.baseEndpoint = this.environment.url;
    this.loadCompany();
  }

  protected post(url: string, data: object): Observable<GeneralResponse<G>> {
    return this.httpClient.post(this.baseEndpoint + url, data);
  }

  protected get(url: string): Observable<GeneralResponse<G>> {
    return this.httpClient.get(this.baseEndpoint + url);
  }

  protected index(url: string): Observable<GeneralResponse<G[]>> {
    return this.httpClient.get(this.baseEndpoint + url);
  }

  protected getById(
    url: string,
    id: number | string
  ): Observable<GeneralResponse<G>> {
    return this.httpClient.get(this.baseEndpoint + url + '/' + id);
  }

  protected indexWithFilters(
    url: string,
    filters: { [index: string]: any } = {}
  ): Observable<GeneralResponse<G[]>> {
    return this.httpClient.get(this.baseEndpoint + url, { params: filters });
  }

  protected getByParams(
    url: string,
    params?: string | undefined
  ): Observable<GeneralResponse<G>> {
    return this.httpClient.get(this.environment.url + url + '?' + params);
  }

  protected patch(
    url: string,
    id?: number | string,
    data?: object,
    action?: string
  ): Observable<GeneralResponse<G>> {
    return this.httpClient.patch(
      this.baseEndpoint + url + '/' + id + (action ? '/' + action : ''),
      data
    );
  }

  protected delete(url: string, id: number): Observable<GeneralResponse<any>> {
    return this.httpClient.delete(this.baseEndpoint + url + '/' + id);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error.errors) {
      for (let errorItem in error.error.errors) {
        const errors = error.error.errors[errorItem];
        //this.toastService.show(errors[0], {'class' : 'bg-danger'});
      }
      return throwError(error.error.errors);
    } else if (error.error.message) {
      return throwError(error.error.message);
    }
    return throwError(error);
  }

  protected loadCompany() {
    let company = localStorage.getItem('company');
    if (company) {
      this.companySlug = company;
      this.locale = 'en';
    }

    this.baseEndpoint =
      this.environment.url +
      '/' +
      this.companySlug +
      '/' +
      this.locale +
      '/backoffice';
  }

  protected removeCompany() {
    this.baseEndpoint = this.environment.url;
  }
}
