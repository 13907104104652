<div class="p-5" *ngIf="isLoading">
  <div class="text-center p-5">
    <img src="/assets/logo.png" style="height: 90px">
  </div>
  <div class="">
    <app-loading></app-loading>
  </div>
  <div class="text-center mt-5 h5">
    Please wait ...
  </div>
</div>
<ng-container *ngIf="!isLoading">
  <router-outlet></router-outlet>
</ng-container>
<div class="toast-container position-fixed top-0 start-0 p-5" style="z-index: 1200;">

  <ngb-toast
    *ngFor="let toast of toastService.toasts"
    [class]="toast.classname"
    [autohide]="true"
    [delay]="toast.delay || 5000"
    (hidden)="toastService.remove(toast)"
  >
    <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
      <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
    </ng-template>

    <ng-template #text>{{ toast.textOrTpl }}</ng-template>
  </ngb-toast>
</div>
